import * as React from 'react';
import DefaultAppView from '../app-view/default-app-view';
import Footer from '../components/footer';
import Header from '../components/header';
import { SEO } from '../components/seo';
import { useSiteStrapiManifest } from '../hooks/site-strapi-manifest';

const Manifest = () => {

    const strapiQuery: any = useSiteStrapiManifest();

    const manifestTitle: string = strapiQuery?.manifestTitle && strapiQuery.manifestTitle;
    const manifestDescription: string = strapiQuery?.manifestDescription && strapiQuery.manifestDescription.data.manifestDescription;

    return (
        <DefaultAppView>
            <Header currentPageTheme='dark'/>
            <div className='flex items-center w-full min-h-screen px-5 bg-black lg:px-36 text-cardinal-white'>
                <div className='flex flex-col items-center w-full'>
                    <div className='flex text-3xl font-bold uppercase lg:text-7xl lg:pt-7'>{manifestTitle}</div>
                    {/* flex flex-wrap w-4/5 pt-8 text-lg lg:pt-16 lg:text-5xl */}
                    <div className='flex flex-wrap justify-center w-full pt-8 space-x-2 text-xl leading-tight lg:w-3/4 lg:pt-16 lg:text-5xl'>
                        {manifestDescription.split(' ').map((letter: string, index: number) => {
                                let letterStyle = {"animationDelay": (1 + index / 4) + "s"}
                                return(     
                                    <span className='text-cardinal-white enlightLetter' style={letterStyle} key={index}>{letter}</span>
                                )
                        })}
                    </div>
                </div>
            </div>
            <Footer/>
        </DefaultAppView>
    )

}

export default Manifest;

export const Head = () => (
    <SEO title='Cardinal - Supersecret Manifest'/>
)