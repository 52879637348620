import { graphql, useStaticQuery } from "gatsby"

export const useSiteStrapiManifest = () => {

    const data: any = useStaticQuery(graphql`
        query {
            strapiManifest {
                manifestTitle
                manifestDescription {
                    data {
                        manifestDescription
                    }
                }
            }
        }
    `)

    return data.strapiManifest;

}